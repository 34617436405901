import type { Experiment, Target, Variant } from './Experiment'
import { Operator } from './Experiment'
import type { Event } from './Events'
import { send } from './Events'
import deepmerge from 'deepmerge'
import EventEmitter from 'eventemitter3'
import { RedirectTimings, send as sendMetrics } from './Metrics'

interface ExperimentsResponse {
  experiments: Experiment[]
}

type EmitterEvents = Event | 'experiment'

interface ExperimentDecision {
  id: string
  variant: string
}

interface Timings {
  initial: boolean
  original: {
    timeOrigin: number
    pageDuration: number
    sdkStart: number
    redirectStart: number
  }
}

interface StoredExperiment {
  [variantId: string]: {
    timings?: Timings
    ts?: string
    events?: {
      [K in Event]?: {
        sent: boolean
      }
    }
  }
}

interface Stored {
  [experimentId: string]: StoredExperiment
}

const toHostPath = (uri: string): string => {
  const parsed = new URL(uri)
  return parsed.hostname + parsed.pathname.replace(/\/$/, '')
}

export default class SDK {
  private experiments: Experiment[]
  private _experiment: Experiment
  private readonly emitter: EventEmitter = new EventEmitter()

  public get experiment (): ExperimentDecision | undefined {
    if (this._experiment === undefined) {
      return
    }

    const variant = this.currentVariant(this._experiment)

    return {
      id: this._experiment.id,
      variant: variant.id.toString()
    }
  }

  constructor () {
    this.init()
  }

  private init (): void {
    this.fetchExperiments()
      .then(async (response) => {
        this.experiments = response.experiments

        const hashParams = new URLSearchParams(window.location.hash.replace('#', '?'))

        const experimentIdParam = hashParams.get('optimize-experiment')
        const variantIdParam = hashParams.get('optimize-variant')

        if (experimentIdParam && variantIdParam) {
          const experiment = this.experiments.find((experiment) =>
            experiment.id === experimentIdParam)

          if (experiment !== undefined) {
            const currentVariant = this.currentVariant(experiment)

            if (currentVariant?.id.toString() === variantIdParam) {
              this._experiment = experiment
              await this.increment('view')
              await this.sendRedirectTimings(experiment, currentVariant)
              return
            }
          }
        }

        await this.processExperiments()
      })
      .catch((error) => console.error(error))
      .finally(() => {
        if (this._experiment !== undefined) {
          this.emitter.emit('experiment')
        }
      })
  }

  private async fetchExperiments (): Promise<ExperimentsResponse> {
    const hostPath = window.location.hostname + window.location.pathname.replace(/\/$/, '')
    const response = await fetch(`https://optimize.clickocean.io/api/experiments?page=${btoa(hostPath)}`, {
      method: 'GET'
    })

    if (!response.ok) {
      throw new Error('Could not fetch experiments')
    }

    if (response.status === 204) {
      return { experiments: [] }
    }

    return await response.json()
  }

  public on (event: EmitterEvents, listener: () => void): void {
    if (event === 'experiment' && this._experiment !== undefined) {
      listener()
      return
    }

    this.emitter.once(event, listener)
  }

  public async increment (event: Event, experiment?: ExperimentDecision): Promise<void> {
    experiment = experiment ?? this.experiment

    if (experiment === undefined) {
      return
    }

    const lsData: Stored = JSON.parse(localStorage.getItem('terra-optimize') || '{}')
    const lsEvent: { sent: boolean } = lsData[experiment.id]?.[experiment?.variant]?.events?.[event]

    if (lsEvent?.sent) {
      return
    }

    const eventToStore: Stored = deepmerge(lsData, {
      [experiment.id]: {
        [experiment?.variant]: {
          ts: lsData[experiment.id]?.[experiment?.variant]?.ts ?? new Date().toISOString(),
          events: {
            [event]: {
              sent: true
            }
          }
        }
      }
    })

    localStorage.setItem('terra-optimize', JSON.stringify(eventToStore))

    await send(event, experiment.id, experiment?.variant)

    this.emitter.emit(event)
  }

  private async processExperiments (): Promise<void> {
    for (const experiment of this.experiments) {
      let relevant = true

      for (const target of experiment.target) {
        if (!this.validateTarget(target)) {
          relevant = false
        }
      }

      if (!relevant) {
        continue
      }

      const chosenVariant = this.chooseVariant(experiment)

      await this.increment('decision', {
        id: experiment.id,
        variant: chosenVariant.id.toString()
      })

      if (toHostPath(chosenVariant.variant.url) === toHostPath(window.location.href)) {
        this._experiment = experiment
        await this.increment('view')
        return
      }

      switch (experiment.type) {
        case 'redirect': {
          const url = new URL(chosenVariant.variant.url)
          const hashParams = new URLSearchParams(location.hash.replace('#', '?'))
          hashParams.set('optimize-experiment', experiment.id)
          hashParams.set('optimize-variant', chosenVariant.id.toString())
          url.search = location.search
          url.hash = hashParams.toString()
          this.storeRedirectMetrics(experiment, chosenVariant)
          this.redirect(url.toString())
          return
        }
      }
    }
  }

  private currentVariant (experiment: Experiment): { id: number, variant: Variant } | undefined {
    const id = experiment.variants.findIndex((variant) =>
      toHostPath(variant.url) === toHostPath(window.location.href))

    return { id, variant: experiment.variants[id] }
  }

  private validateTarget (target: Target): boolean {
    switch (target.type) {
      case 'url':
        return this.processOperators(target.operator, target.value.map(toHostPath), toHostPath(window.location.href))

      case 'search-query': {
        const hashParam = new URLSearchParams(window.location.hash.replace('#', '?')).get(target.variable)
        const searchParam = new URLSearchParams(window.location.search).get(target.variable)
        const param = hashParam || searchParam

        return this.processOperators(target.operator, target.value, param)
      }
    }

    return false
  }

  private chooseVariant (experiment: Experiment): { id: number, variant: Variant, initial: boolean } {
    let initial = false
    const lsData: Stored = JSON.parse(localStorage.getItem('terra-optimize') || '{}')
    for (const [id, variant] of experiment.variants.entries()) {
      if (lsData[experiment.id]?.[id] !== undefined) {
        return { id, variant, initial }
      }
    }

    const totalWeight = experiment.variants.reduce((acc, variant) => acc + variant.weight, 0)
    const random = Math.random() * totalWeight

    let weight = 0
    for (const [id, variant] of experiment.variants.entries()) {
      weight += variant.weight
      if (random < weight) {
        initial = true
        this.updateLS({ [experiment.id]: { [id]: { ts: new Date().toISOString() } } })
        return { id, variant, initial }
      }
    }

    return { id: 0, variant: experiment.variants[0], initial }
  }

  private redirect (url: string): void {
    window.location.assign(url)
  }

  private processOperators (operator: Operator, value: string[], target: string): boolean {
    switch (operator) {
      case 'matches':
        for (const v of value) {
          if (target.toLowerCase().replace('www.', '') === v.toLowerCase().replace('www.', '')) {
            return true
          }
        }
        break
      case 'equals':
        for (const v of value) {
          if (v === target) {
            return true
          }
        }
        break
      case 'does-not-equal':
        for (const v of value) {
          if (v === target) {
            return false
          }
        }
        return true
    }

    return false
  }

  private updateLS (data: Stored): void {
    const lsData: Stored = JSON.parse(localStorage.getItem('terra-optimize') || '{}')

    localStorage.setItem('terra-optimize', JSON.stringify(deepmerge(lsData, data)))
  }

  private storeRedirectMetrics (experiment: Experiment, chosenVariant: { id: number, variant: Variant, initial: boolean }): void {
    try {
      // sampling
      if (Math.random() > 1) {
        return
      }

      const timeOrigin = performance.timeOrigin

      const page = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming
      const sdk = performance.getEntriesByType('resource')
        .filter(({ name }) => name.startsWith('https://optimize.clickocean.io/sdk/sdk.js'))[0] as PerformanceResourceTiming

      const timings: Timings = {
        initial: chosenVariant.initial,
        original: {
          timeOrigin,
          pageDuration: page.duration,
          sdkStart: sdk.startTime,
          redirectStart: performance.now()
        }
      }

      this.updateLS({ [experiment.id]: { [chosenVariant.id]: { timings } } })
    } catch (_e) {
      // ignore
    }
  }

  private async sendRedirectTimings (experiment: Experiment, chosenVariant: { id: number, variant: Variant }): Promise<void> {
    try {
      const lsData: Stored = JSON.parse(localStorage.getItem('terra-optimize') || '{}')
      const timings = lsData[experiment.id]?.[chosenVariant.id]?.timings

      if (typeof timings === 'undefined' || timings === null) {
        return
      }

      const timeOrigin = performance.timeOrigin

      const page = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming
      const sdk = performance.getEntriesByType('resource')
        .filter(({ name }) => name.startsWith('https://optimize.clickocean.io/sdk/sdk.js'))[0] as PerformanceResourceTiming

      const metrics: RedirectTimings = {
        originalPageDuration: timings.original.pageDuration,
        originalSdkStart: timings.original.sdkStart,
        originalChoiceDuration: timings.original.redirectStart - timings.original.sdkStart,
        variantRedirectDuration: timeOrigin - (timings.original.timeOrigin + timings.original.redirectStart),
        variantPageDuration: page.duration,
        variantSdkStart: sdk.startTime,
        variantPageTotalDuration: (timeOrigin - timings.original.timeOrigin) + page.duration
      }

      const tags = {
        experiment: experiment.id,
        variant: chosenVariant.id.toString(),
        initial: timings.initial.toString()
      }

      this.updateLS({ [experiment.id]: { [chosenVariant.id]: { timings: undefined } } })

      await sendMetrics({ label: 'redirect', metrics }, tags)
    } catch (_e) {
      // ignore
    }
  }
}
